.nx-catalog-search {
    width: 100%;

    .selectize-control.plugin-drag_drop.multi>.selectize-input>div.ui-sortable-placeholder {
        visibility: visible !important;
        background: #f2f2f2 !important;
        background: rgba(0, 0, 0, 0.06) !important;
        border: 0 none !important;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
        content: '!';
        visibility: hidden;
    }

    .selectize-control.plugin-drag_drop .ui-sortable-helper {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .selectize-dropdown-header {
        position: relative;
        padding: 3px 12px;
        border-bottom: 1px solid #d0d0d0;
        background: #f8f8f8;
        -webkit-border-radius: 4px 4px 0 0;
        -moz-border-radius: 4px 4px 0 0;
        border-radius: 4px 4px 0 0;
    }

    .selectize-dropdown-header-close {
        position: absolute;
        right: 12px;
        top: 50%;
        color: #333333;
        opacity: 0.4;
        margin-top: -12px;
        line-height: 20px;
        font-size: 20px !important;
    }

    .selectize-dropdown-header-close:hover {
        color: #000000;
    }

    .selectize-dropdown.plugin-optgroup_columns .optgroup {
        border-right: 1px solid #f2f2f2;
        border-top: 0 none;
        float: left;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
        border-right: 0 none;
    }

    .selectize-dropdown.plugin-optgroup_columns .optgroup:before {
        display: none;
    }

    .selectize-dropdown.plugin-optgroup_columns .optgroup-header {
        border-top: 0 none;
    }

    .selectize-control.plugin-remove_button [data-value] {
        position: relative;
        padding-right: 24px !important;
    }

    .selectize-control.plugin-remove_button [data-value] .remove {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 17px;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        color: inherit;
        text-decoration: none;
        vertical-align: middle;
        display: inline-block;
        padding: 1px 0 0 0;
        border-left: 1px solid rgba(0, 0, 0, 0);
        -webkit-border-radius: 0 2px 2px 0;
        -moz-border-radius: 0 2px 2px 0;
        border-radius: 0 2px 2px 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .selectize-control.plugin-remove_button [data-value].active .remove {
        border-left-color: rgba(0, 0, 0, 0);
    }

    .selectize-control.plugin-remove_button .disabled [data-value] .remove:hover {
        background: none;
    }

    .selectize-control.plugin-remove_button .disabled [data-value] .remove {
        border-left-color: rgba(77, 77, 77, 0);
    }

    .selectize-control.plugin-remove_button .remove-single {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 23px;
    }

    .selectize-control {
        position: relative;
    }

    .selectize-dropdown,
    .selectize-input,
    .selectize-input input {
        color: #333333;
        font-family: inherit;
        font-size: inherit;
        line-height: 20px;
        -webkit-font-smoothing: inherit;
    }

    .selectize-input.items.not-full.has-options #select-state-selectized::placeholder {
        opacity: 1;
        transition: ease-out 1s;
        font-size: 14px;
    }

    .selectize-input.items.not-full.has-options.input-active #select-state-selectized::placeholder {
        opacity: 0;
        transition: ease-out 1s;
        font-size: 10px;
    }

    .selectize-input,
    .selectize-control.single .selectize-input.input-active {
        background: #fff;
        cursor: text;
        display: inline-block;
        -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, .2);
        -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, .2);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, .2);
    }

    .selectize-input {
        padding: 14px 12px;
        display: inline-block;
        width: 100%;
        height: 50px;
        overflow: hidden;
        position: relative;
        z-index: 1;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 10px;
    }

    .selectize-control.multi .selectize-input.has-items {
        padding: 5px 12px 2px;
    }

    .selectize-input.full {
        background-color: #fff;
    }

    .selectize-input.disabled,
    .selectize-input.disabled * {
        cursor: default !important;
    }

    .selectize-input.focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .selectize-input.dropdown-active {
        -webkit-border-radius: 4px 4px 0 0;
        -moz-border-radius: 4px 4px 0 0;
        border-radius: 4px 4px 0 0;
    }

    .selectize-input>* {
        vertical-align: baseline;
        display: -moz-inline-stack;
        display: inline-block;
        zoom: 1;
        *display: inline;
    }

    .selectize-control.multi .selectize-input>div {
        cursor: pointer;
        margin: 0 3px 3px 0;
        padding: 1px 3px;
        background: #efefef;
        color: #333333;
        border: 0 solid rgba(0, 0, 0, 0);
    }

    .selectize-control.multi .selectize-input>div.active {
        background: #428bca;
        color: #fff;
        border: 0 solid rgba(0, 0, 0, 0);
    }

    .selectize-control.multi .selectize-input.disabled>div,
    .selectize-control.multi .selectize-input.disabled>div.active {
        color: #808080;
        background: #ffffff;
        border: 0 solid rgba(77, 77, 77, 0);
    }

    .selectize-input>input {
        display: inline-block !important;
        padding: 0 !important;
        min-height: 0 !important;
        max-height: none !important;
        max-width: 100% !important;
        margin: 0 !important;
        text-indent: 0 !important;
        border: 0 none !important;
        background: none !important;
        line-height: inherit !important;
        -webkit-user-select: auto !important;
        user-select: auto !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        width: 100% !important;
    }

    .selectize-input>input::-ms-clear {
        display: none;
    }

    .selectize-input>input:focus {
        outline: none !important;
    }

    .selectize-input::after {
        content: ' ';
        display: block;
        clear: left;
    }

    .selectize-input.dropdown-active::before {
        content: ' ';
        display: block;
        position: absolute;
        background: #ffffff;
        height: 1px;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .selectize-dropdown {
        position: absolute;
        z-index: 10;
        border: 1px solid #d0d0d0;
        background: #fff;
        margin: -1px 0 0 0;
        border-top: 0 none;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-border-radius: 0 0 4px 4px;
        -moz-border-radius: 0 0 4px 4px;
        border-radius: 0 0 4px 4px;
    }

    .selectize-dropdown [data-selectable] {
        cursor: pointer;
        overflow: hidden;
    }

    .selectize-dropdown [data-selectable] .highlight {
        background: transparent;
        font-weight: 600;
    }

    .selectize-dropdown .option,
    .selectize-dropdown .optgroup-header {
        padding: 3px 12px;
    }

    .selectize-dropdown .option,
    .selectize-dropdown [data-disabled],
    .selectize-dropdown [data-disabled] [data-selectable].option {
        cursor: inherit;
        opacity: 0.5;
    }

    .selectize-dropdown [data-selectable].option {
        opacity: 1;
        padding-top: 7px;
    }

    .selectize-dropdown .optgroup:first-child .optgroup-header {
        border-top: 0 none;
    }

    .selectize-dropdown .optgroup-header {
        color: #777777;
        background: #fff;
        cursor: default;
    }

    .selectize-dropdown .active {
        cursor: pointer;
        background-color: #f2f2f2;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }

    .selectize-dropdown .active.create {
        color: #262626;
    }

    .selectize-dropdown .create {
        color: rgba(51, 51, 51, 0.5);
    }

    .selectize-dropdown-content {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 250px;
        -webkit-overflow-scrolling: touch;
        border-radius: 10px;
    }

    .selectize-control.single .selectize-input.input-active,
    .selectize-control.single .selectize-input.input-active input {
        cursor: text;
    }

    .selectize-control.single .selectize-input:after {
        display: none;
    }

    .selectize-control.single .selectize-input.dropdown-active:after {
        margin-top: -4px;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent #333333 transparent;
    }

    .selectize-control.rtl.single .selectize-input:after {
        left: 17px;
        right: auto;
    }

    .selectize-control.rtl .selectize-input>input {
        margin: 0 4px 0 -2px !important;
    }

    .selectize-control .selectize-input.disabled {
        opacity: 0.5;
        background-color: #fff;
    }

    .selectize-dropdown,
    .selectize-dropdown.form-control {
        height: auto;
        padding: 0;
        z-index: 1000;
        background: #fff;
        border: none;
        -webkit-border-radius: 0 0 10px 10px;
        -moz-border-radius: 0 0 10px 10px;
        border-radius: 0 0 10px 10px
    }

    .selectize-dropdown .optgroup-header {
        font-size: 12px;
        line-height: 1.42857143;
    }

    .selectize-dropdown .optgroup:first-child:before {
        display: none;
    }

    .selectize-dropdown .optgroup:before {
        content: ' ';
        display: block;
        height: 1px;
        margin: 10px 0;
        overflow: hidden;
        background-color: #e5e5e5;
        margin-left: 10px;
        margin-right: 10px;
    }

    .selectize-dropdown-content {
        padding: 5px;
    }

    .selectize-dropdown-header {
        padding: 6px 12px;
    }

    .selectize-input {
        min-height: 34px;
    }

    .selectize-input.dropdown-active {
        -webkit-border-radius: 10px 10px 0 0;
        -moz-border-radius: 10px 10px 0 0;
        border-radius: 10px 10px 0 0;
    }

    .selectize-input.dropdown-active::before {
        display: none;
    }

    .selectize-input.focus {
        border-color: #66afe9;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

#catalog-search {
    display: flex;
    width: 90%;
}

@media (max-width: 991px) {
    #catalog-search {
        width: 100%;
    }
}